/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages

          // Bootstrap menu magic
          $(function() {
            // Bootstrap menu magic
            
            function click_or_hover_dropdowns(){
              // check to see if the device has the is-touch class
              if ($( "body" ).hasClass( "is-touch" ) || $(window).width() < 992){

                $(".dropdown-toggle").attr('data-toggle', 'dropdown');
                
                // // Unbind the hover on the click of the top menu item
                $('.navs-standard>.dropdown.open').click(function(){
                    $(this).removeClass('open');
                });
              } else {
                $(".dropdown-toggle").removeAttr('data-toggle dropdown');
                $('.navs-standard>.dropdown').on('mouseenter',function(){
                  $(this).addClass('open');
                });
                $('.navs-standard>.dropdown').on('mouseleave',function(){
                  $(this).removeClass('open');
                });
              }
            }
            $(window).on('resize', click_or_hover_dropdowns);
            click_or_hover_dropdowns();
            
          });

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
         // JavaScript to be fired on all pages, after page specific JS is fired
        
         // Toggle Search form
         $('#search-popover').popover({ 
          html : true,
          placement: "bottom", 
          content: function() {
            return $("#popover-content").html();
          }
        });

        /* Close popover */
        $(document).on('click', function (e) {
            $('[data-toggle="popover"],[data-original-title]').each(function () {
                //the 'is' for buttons that trigger popups
                //the 'has' for icons within a button that triggers a popup
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {                
                    (($(this).popover('hide').data('bs.popover')||{}).inState||{}).click = false;  // fix for BS 3.3.6
                }
        
            });
        });

         // Set min page height
         function setMinPageHeight(){
          //determine the viewport height
          var viewportHeight;
          if (document.compatMode === 'BackCompat') {
              viewportHeight = document.body.clientHeight;
          } else {
              viewportHeight = document.documentElement.clientHeight;
          }
          viewportHeight = parseInt(viewportHeight);
          
          //determine the body height
          var bodyHeight;
          bodyHeight = $('body').css('height');
          bodyHeight = parseInt(bodyHeight);
          
          //determine the footer height
          var footerHeight;
          footerHeight = $('footer.content-info').css('height');
          footerHeight = parseInt(footerHeight);

          //set the min page-content height
          /*
          ** if the body height less than the viewport height, 
          ** then set the height of the body to the viewport hight 
          ** and position the footer absolutely at thebootom of the body
          */

          if(bodyHeight - footerHeight < viewportHeight - footerHeight ){
            $('body').css('height',viewportHeight);
            $('footer.content-info').css({
              'position' : 'absolute',
              'bottom': '0',
              'width': '100%'
            });
          } 
            
        }
        setMinPageHeight();
      
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS

        function slideChange(args) {
          if (!args.slideChanged) { return false; }
            $(args.sliderObject).find('img').attr('style', '');
            $('.banner-description-container').removeClass('active');
            $(args.currentSlideObject).find('.banner-description-container').addClass('active');
            $(args.currentSlideObject).find('img').animate({
              opacity: '1'
            }, 0, 'easeOutQuint');
            $('.item').removeClass('active');
            $(args.currentSlideObject).toggleClass('active');
        }

        function slideComplete(args) {
          if (!args.slideChanged) { return false; }

          $(args.sliderObject).find('.banner-description-container').attr('style', '');
          $(args.currentSlideObject).find('.banner-description-container').animate({
            opacity: '1'
          }, 400, 'easeOutQuint');

          console.log('slide complete');
        }

        function sliderLoaded(args) {

          $(args.currentSlideObject).find('img').animate({
            opacity: '1'
          }, 0, 'easeOutQuint');

          $(args.sliderObject).find('.banner-description-container').attr('style', '');

          $(args.currentSlideObject).find('.banner-description-container').animate({
            opacity: '1'
          }, 400, 'easeOutQuint');
          $('.banner-description-container').removeClass('active');
          $(args.currentSlideObject).find('.banner-description-container').addClass('active');
          $(args.currentSlideObject).toggleClass('active');
          console.log('slide loaded');
        }
        $('.iosSlider').iosSlider({
          snapToChildren: true,
          desktopClickDrag: true,
          infiniteSlider: true,
          snapSlideCenter: true,
          onSlideComplete: slideComplete,
          onSlideChange: slideChange,
          onSliderLoaded: sliderLoaded,
          autoSlide: true,
          autoSlideTimer: 5000,
        });

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    },
    // Join page, note the change from about-us to about_us.
    'join': {
      init: function () {
        // JavaScript to be fired on the about us page
        // JavaScript to be fired on the about us page
        $(function(){
          $('input[type="submit"]').toggleClass( 'btn btn-primary');
        });

        $(document).bind('gform_post_render', function(){            
          $('input[type="submit"]').toggleClass( 'btn btn-primary');
        });
      }
    },

    // Join page, note the change from about-us to about_us.
    'cohort_registration': {
      init: function () {
        // JavaScript to be fired on the about us page
        // JavaScript to be fired on the about us page
        $(function(){
          $('input[type="submit"]').toggleClass( 'btn btn-primary');
        });

        $(document).bind('gform_post_render', function(){            
          $('input[type="submit"]').toggleClass( 'btn btn-primary');
        });
      }
    },
    // Equipment page.
    'post_type_archive_equipment': {     
      init: function () {
        // JavaScript to be fired on the equipment page

        $('.thumbnail').click(function(){
          //$('.modal-body').empty();
          var title = $(this).data("title");
          $('.modal-title').html(title);
          
          var organisation = $(this).data("organisation");
          var location = $(this).data("location");
          organisation = '<p><strong>Organisation</strong>: '+organisation+'</p>';
          $('.equip-organisation').html(organisation);

          
          location = '<p ><strong>Location</strong>: '+location+'</p>';
          $('.equip-location').html(location);

          var details = $(this).data('details');
          details = '<h4>Details</h4>'+details;
          $('.equip-details').html(details);
          
          var image = $(this).data('image');
          image = '<img src="'+image+'" alt="'+title+'" class="img-responsive" >';
          $('#equip-image').html(image);

          var name = $(this).data('name');
          var phone = $(this).data('phone');
          var email = $(this).data('email');
          var contact;
          if(name.length>0 || phone.length>0 || email.length>0 ){
            contact = '<p class="contact">';
            if(name.length>0){
              contact += '<span class="contact-name"><strong>Contact:</strong> '+name+'</span>';
            }
            if(email.length>0){
              contact += '  <span class="contact-email"><strong>Email:</strong>  '+email+'</span>';
            }
            if(phone.length>0){
              contact += '  <span class="contact-phone"><strong>Phone:</strong>  '+phone+'</span>';
            }
            contact += '</p>';
          } 
          $('.equip-contact').html(contact);


          //$($(this).parents('div').html()).appendTo('#equip-details');          
          $('#myModal').modal({show:true});
      });

      /* Filter for the equipment page */
      // $( "#equipment-filter" ).change(function() {
      //     var slectedClass = $( "select option:selected" ).val();
      //     console.log(slectedClass);
      //     $('.instrument').hide().filter('[class~="'+slectedClass+'"]').show();
      // });

      /* change the default label on the equipment filter */
      $('.beautiful-taxonomy-filters-tax label[for="select-facility"]').text('Filter Equipment by Technique');
      $('.beautiful-taxonomy-filters-tax label[for="select-location"]').text('Filter Equipment by Location');

      }
    },
    // Contact us page, note the change from contact-us to contact_us.
    'contact_us': {
      init: function () {
        // JavaScript to be fired on the about us page
        $(function(){
          $('input[type="submit"]').toggleClass( 'btn btn-primary');
        });

        $(document).bind('gform_post_render', function(){            
          $('input[type="submit"]').toggleClass( 'btn btn-primary');
        });


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
